.preview-modal {
  width: auto !important;
  .ant-modal-content {
    width: 95%;
  }
}

.btn_submit_job {
  width: 140px;
}

.image-upload-grid {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 96% !important;
    height: 150px !important;
    background-color: white !important;
  }
  .ant-upload-list-picture-card-container {
    width: 90% !important;
    height: 150px !important;
    background-color: white !important;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    transition: none !important;
  }
  .ant-upload-list-picture-card {
    transition: none !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    transition: none;
  }
  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
    transition: none;
  }
  span.ant-upload-list-item-actions {
    transition: none;
  }
}
.fonts_weight {
  color: #495057;
  font-size: 12px;
  font-weight: 400;
}
.fonts_weight_bold {
  font-weight: 600 !important;
}

.section-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  margin-bottom: 32px;
}
.section-title {
  color: var(--bold-text, rgba(0, 0, 0, 0.85));

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.section-input .ant-form-item-label {
  label {
    color: #495057;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.assessmentContainer {
  // border: 1px solid var(--wrapper-bg, #DADADA);
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
.assessmentContainerTitle {
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
.assessmentContainer-Header {
  display: flex !important;
  justify-content: flex-end;
  border-bottom: 1px solid var(--wrapper-bg, #dadada);
  padding: 8px;
}
.assessmentItem {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  // align-self: stretch;
  padding: 8px;
  text-align: end;
}
.assessmentItemText {
  color: var(--faded-black-25, rgba(0, 0, 0, 0.25)) !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.addAssessment {
  margin-top: 16px;

  border: 2px dashed var(--faded-black-25, rgba(0, 0, 0, 0.103)) !important;
  // color: var(--body-text, #495057) !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.assessmentInput .ant-form-item {
  margin-bottom: 0px !important;
}

.editorCol {
  padding-bottom: 0px;
  .mce-content-body {
    // margin: 10px 10px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    min-height: 80px;
    max-height: auto;
    padding: 6px 6px;

    p {
      margin-bottom: 0;
    }
  }
  .mce-content-body::before {
    padding: 0px 6px;
  }
}
