.search-select .ant-select-selection-placeholder {
  margin-top: 12px !important;
}

.filter-search {
  border-radius: 35px 0px 0px 35px !important;
  border-right: unset !important;
}
.search-button {
  background-color: #2e4a79 !important;
  color: #fff !important;
  padding: 0.2rem 1.5rem !important;
  margin-left: 5px !important;
  border-radius: 35px !important;
  height: 50px !important;
}
.ant-form-item-label > label::after {
  content: "" !important;
}

.hide-label {
  label {
    visibility: hidden !important;
  }
}

.tiltle-select {
  .ant-select-selector {
    border-radius: 0px 5px 5px 0px !important;
    height: 100% !important;
    padding: 0.2rem 0.75rem !important;
    border-left: unset !important;
  }
}
.source-select .ant-select-selector {
  border-radius: 20px !important;
  padding: 0.255rem 0.75rem !important;
  height: 100% !important;
}
.search-type .ant-select-selector {
  padding: 3px 10px !important;
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
  min-height: 50px !important;
}

.select-search-type .ant-select-selection-placeholder {
  margin-top: 3px !important;
}
.search-select .ant-select-selection-placeholder {
  margin-top: 12px !important;
}
.ant-select-dropdown {
  width: 100px !important;
}
.container-Search {
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}
.container-Search {
  .ant-select.ant-select-in-form-item {
    width: 120px !important;
    text-align: center;
  }
}
.antd-card {
  .ant-card {
    box-shadow: none !important;
  }
}
.ant-select-selection-item {
  margin-top: 2px !important;
}
.search-select .ant-select-selection-item {
  margin-top: 6px !important;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-next
  .ant-pagination-item-link {
  margin-left: 10px !important;
}
.ant-table-pagination.ant-pagination {
  margin-right: 6px !important;
}
