/* ==============
  Tables
===================*/

tr {
  &.even {
    background: #ececec;
  }
  &.head {
    background: #2b3a4a;
    color: #fff;
  }
}

thead {
  background: #2b3a4a;
  color: #fff;
}

th {
  font-weight: 500;
}

.table > tbody > tr > td,
.table > tfoot > tr > td,
.table > thead > tr > td {
  padding: 15px 12px;
}

.table-hover tbody tr:hover,
.table-striped tbody tr:nth-of-type(odd),
.thead-default th {
  background-color: $gray-100;
}

.table td,
.table th {
  vertical-align: middle;
}

.table-vertical {
  td {
    vertical-align: middle;
  }
}

.table-sm {
  th,
  td {
    padding: 0.3rem !important;
  }
}

/* == Responsive Table ==*/
table.focus-on {
  tbody {
    tr.focused {
      th {
        background-color: $primary;
        color: $white;
      }
      td {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

.table-rep-plugin {
  .btn-toolbar {
    display: block;
  }
  .table-responsive {
    border: none !important;
  }
  .btn-group.float-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  tbody {
    th {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .checkbox-row {
    padding-left: 40px;

    label {
      display: inline-block;
      padding-left: 5px;
      position: relative;
      &::before {
        -o-transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        background-color: $white;
        border-radius: 3px;
        border: 1px solid $gray-300;
        content: "";
        display: inline-block;
        height: 17px;
        left: 0;
        margin-left: -20px;
        position: absolute;
        transition: 0.3s ease-in-out;
        width: 17px;
        outline: none !important;
      }
      &::after {
        color: $gray-200;
        display: inline-block;
        font-size: 11px;
        height: 16px;
        left: 0;
        margin-left: -20px;
        padding-left: 3px;
        padding-top: 1px;
        position: absolute;
        top: -1px;
        width: 16px;
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
      z-index: 1;
      outline: none !important;

      &:disabled + label {
        opacity: 0.65;
      }
    }
    input[type="checkbox"]:focus + label {
      &::before {
        outline-offset: -2px;
        outline: none;
      }
    }
    input[type="checkbox"]:checked + label {
      &::after {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }
    }
    input[type="checkbox"]:disabled + label {
      &::before {
        background-color: $gray-100;
        cursor: not-allowed;
      }
    }
    input[type="checkbox"]:checked + label {
      &::before {
        background-color: $primary;
        border-color: $primary;
      }
      &::after {
        color: $white;
      }
    }
  }
  .fixed-solution {
    .sticky-table-header {
      top: 70px !important;
    }
  }
}

@media (max-width: 768px) {
  .table-rep-plugin {
    .fixed-solution {
      .sticky-table-header {
        top: 145px !important;
      }
    }
  }
}

// data tables

.dataTable {
  thead {
    background-color: $navy !important;
    tr {
      th {
        &.sorting,
        &.sorting_desc,
        &.sorting_asc {
          &:before,
          &:after {
            position: absolute;
            bottom: 0.9em;
            display: block;
            opacity: 0.3;
          }
          &:before {
            right: 1em;
            content: "\2191";
          }
          &:after {
            right: 0.5em;
            content: "\2193";
          }
        }

        &.sorting_asc {
          &:before {
            opacity: 1;
          }
        }
        &.sorting_desc {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_length {
    label {
      font-weight: normal;
      text-align: left;
      white-space: nowrap;

      select {
        width: auto;
        display: inline-block;
      }
    }
  }
  .dataTables_filter {
    text-align: right;

    label {
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
      input {
        margin-left: 0.5em;
        display: inline-block;
        width: auto;
      }
    }
  }

  .dataTables_paginate {
    ul {
      &.pagination {
        margin: 2px 0;
        white-space: nowrap;
        justify-content: flex-end;
      }
    }
  }
}
.ant-table-small {
  .ant-table-thead {
    & > tr {
      & > th {
        background-color: $primary;
      }
    }
  }
}
.ant-table-thead {
  & > tr {
    & > th {
      color: $white;
      background-color: $primary;
      text-align: left;
      padding: 14px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      // background: $white;
      border-bottom: 1px solid $primary;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
    }
  }
}

td {
  &.border-right {
    border-right: 2px solid $navy;
  }
}

.indicitor {
  width: 25px;
  height: 20px;
  display: block;
}

.skills-table {
  thead {
    tr {
      td {
        padding: 0.32rem 0.5rem;
      }
    }
  }
  tbody {
    tr {
      td {
        padding-bottom: 0.3rem;
        padding-top: 0.3rem;
      }
      td:nth-child(1) {
        padding-right: 1rem;
        font-size: 13px;
        min-width: 150px;
      }
      td:nth-child(2) {
        padding-right: 0.5rem;
        border-right: 2px solid $primary;
      }
      td:nth-child(3) {
        padding-left: 0.5rem;
      }
    }
  }
}

tr {
  &.skill-cat {
    td {
      background: $bg-body;
      padding: 0.32rem 0.5rem;
      margin-top: 1rem;
    }
  }
}

td {
  &.w-150 {
    min-width: 150px;
  }
}

.add-skills-table-wrapper {
  h4 {
    font-size: 14px;
    text-transform: uppercase;
    padding: 0.2rem 0.5rem;
    background-color: $gray-300;
  }

  .custom-btn {
    font-size: 12px;
    margin-bottom: 0.6rem;
  }
  .ant-table-thead > tr > th {
    padding: 4px 7px !important;
    font-size: 13px !important;
  }
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-input .ant-select-selector,
  .ant-input {
    font-size: 12px;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 0px 8px;
    font-size: 13px;
  }
}
