// .ant-col.ant-col-9 {
//   padding-left: 18px !important;
//   padding-right: 27px !important;
// }
.bench {
  padding-left: 37px !important;
  padding-right: 15px !important;
  font-size: 28px;
  width: 275px !important;
  .span-wide {
    width: 275px !important;
  }
}
.ant-row {
  margin-left: 0px !important;
}
