.resume-preview {
  div#pdf-controls {
    display: none;
  }
}
.resume-preview {
  div#pdf-renderer {
    background-color: #ffffff;
  }
}
