// @import "../../../../assets/scss/variables";

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  border-top: none !important;
}

.ant-modal-header {
  border-bottom: none !important;
}
.ant-modal-title {
  margin-top: 20px !important;
}
.ant-modal-body {
  display: flex !important;
  flex-direction: column;
  margin: 16px !important;
  margin-top: -10px !important;

  width: auto !important;
}

.ant-modal-body .ant-btn {
  background-color: #f5f7f9 !important;
  color: #000000d9 !important;
  width: auto;
  height: auto;
  border-radius: 8px;
  border: "none";
  margin-right: 8px;
}
.ant-modal-body .ant-btn.Experience {
  background-color: #990000cc !important;
  color: #ffffff !important;
}

.ant-modal-body .ant-btn.Traits {
  background-color: #2e4a79e5 !important;
  color: #ffffff !important;
}
.ant-modal-body .ant-btn.Drivers {
  background-color: #008d8b !important;
  color: #ffffff !important;
}

.view-switches {
  margin: 8px;
  margin-left: 0px;
}

.table-row {
  width: 100%;
  background: #f5f7f9;
  // margin:12px !important;
  padding: 12px !important;
}

.radio-group-with-gap .ant-radio-button-wrapper {
  margin-left: 16px;
  cursor: default;
  border: none;
  margin-top: 8px;
  border-radius: 8px !important;
  width: 72px !important;
  height: 72px !important;
  box-shadow: none;
}
.Experiences .ant-radio-button-wrapper {
  background-color: #990000cc;
}
.Traits .ant-radio-button-wrapper {
  background-color: #2e4a79e5;
}
.Drivers .ant-radio-button-wrapper {
  background-color: #008d8b;
}

.radio-group-with-gap .ant-radio-button-wrapper-checked {
  background-color: #bc9623cc !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: #ff787500 !important;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff787500 !important;
  margin-left: 0px;
}
