.resume-preview {
  div#pdf-controls {
    display: none;
  }
}
.resume-preview {
  .jGqOvK {
    background: none;
  }
}
