.fit-to-screen {
  object-fit: cover;
  object-position: center;
}

.trim-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-align-links.ant-space.ant-space-horizontal.ant-space-align-center {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
