.ant-form-item-label > label::after {
  content: "";
}

.ant-row .ant-form-item {
  display: block;
}

.hide-label {
  label {
    visibility: hidden;
  }
}

.filter-search {
  border-radius: 20px 0px 0px 20px;
  border-right: unset;
}

.tiltle-select {
  .ant-select-selector {
    border-radius: 0px 5px 5px 0px !important;
    height: 100% !important;
    padding: 0.2rem 0.75rem !important;
    border-left: unset !important;
  }
}

.filter-btn {
  border-radius: 8px !important;
  height: 50;
  width: 100;
  background-color: "#E6E6E6";
}

.filter-btn:hover,
.filter-btn:active,
.filter-btn:focus {
  border-radius: 8px !important;
  border-color: inherit;
  color: inherit;
}

.filter-btn-active,
.filter-btn-active:hover,
.filter-btn-active:active,
.filter-btn-active:focus {
  border-radius: 8px !important;
  background-color: #5b626b;
  color: white !important;

  path {
    fill: white;
  }
  .action {
    transform: rotate(90deg);
  }
}

.source-select .ant-select-selector {
  border-radius: 20px !important;
  padding: 0.255rem 0.75rem !important;
  height: 100% !important;
}

.search-button {
  background-color: #2e4a79;
  color: #fff;
  padding: 0.2rem 1.5rem;
  margin-left: 5px;
  border-radius: 20px;
  height: 38px;
}

.ant-select-dropdown {
  width: 100px !important;
}

.search-type .ant-select-selector {
  padding: 3px 10px !important;
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}

label {
  margin-bottom: 10px;
  display: block;
}

.filters-wrapper {
  margin-top: 10px;
}

.candidate-list-wrapper {
  display: flex;
  flex-direction: column;
}

// .candidate-list-wrapper div:first-child {
//   text-align: right;
// }

.edit-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.edit-drawer .ant-drawer-header-title button {
  margin: 0;
}

.row-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #2e4a7a;
}

.top-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4a7a;
  border-color: #fff;
}
.ellipse {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .ant-modal-footer {
//   display: none;
// }
.ant-modal-body {
  .ant-btn {
    background-color: #dab137 !important;
    color: #ffffff !important;
    border-radius: 65px;
    width: 206px;
    height: 40px;
    outline: none;
  }
}
span.anticon.anticon-close.ant-modal-close-icon {
  color: #2e4a79 !important;
  font-size: 18px !important;
}
.ant-table-thead th.ant-table-column-sort {
  background-color: #2e4a79 !important;
}
.ant-table-thead th.ant-table-column-sort:hover {
  background-color: #293847 !important;
}

.ant-table-thead
  th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background-color: #293847 !important;
}
.ant-modal-body {
  .bg-img {
    position: relative;
    background-image: url("./chatModal.png");
    background-size: cover;
    background-position: center;
    // width: 490px;
    width: auto;
    height: 230px;
    border-radius: 10px;
    margin: 0 -16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .bg-img {
    p {
      position: absolute;
      font-size: 42px;
      font-weight: 700;
      color: #2e4a79;
      margin-top: 75px;
      text-align: center;
      margin-left: 29%;
      font-family: "lato";
    }
  }
  .ant-avatar-group {
    left: 0;
    margin-top: -90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-avatar-group {
    .ant-avatar {
      border: 4px solid #ffffff;
    }
  }
}
.spaceGap {
  display: flex;
  align-items: center;
  justify-content: start;
  // gap: 2px !important;
  margin-left: 10px;
}
.spaceGap_NewCandidate {
  display: flex;
  align-items: center;
  justify-content: start;
  // gap: 2px !important;
  // margin-left: -12px !important;
}
.candidate_count_design {
  font-size: 11px !important;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a838c;
  color: white !important;
  border-radius: 16px;
  min-width: 23px;
  height: 18px;
  padding: 4px 6px 3px 6px !important;
  gap: 10px;
}
.custom-tabs {
  .ant-tabs-tab {
    transition: none !important;
  }
}
.hidden {
  visibility: hidden !important;
}
.custom-tabs {
  .ant-tabs-tab {
    // margin-top: 5px !important;
    overflow: none !important;
  }
  .ant-tabs-nav-wrap {
    overflow: visible !important ;
  }
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  display: none !important;
}
.benchmark-plaque {
  display: flex;
  padding: 4px 4px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #2e4a79;
  background: #2e4a79;
  color: #fff;
  width: 116px;
  font-size: 12px;
  font-weight: 500;
}
