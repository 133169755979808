/* ==============
  Bootstrap-custom
===================*/

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: $base-font;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.08);
  background-color: $white;
  border-color: transparent;
  margin: 0;
}
.dropdown-item {
  background-color: $white;
  padding: 0.55rem 1.5rem;

  &:active,
  &:hover {
    background-color: $gray-100;
    color: $dark;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $gray-100;
  color: $dark;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: $muted;
  content: "\f105" !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-center {
  text-align: center;
}
//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-teal {
  background-color: darken($teal, 25%);
}

.bg-navy {
  background-color: $navy !important;
}

.bg-amber {
  background-color: $amber !important;
}

.bg-brand {
  background-color: $brand-color !important;
}

.bg-burgundy {
  background-color: $burgundy !important;
}
//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}
.text-justify {
  text-align: justify !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}

.text-brand {
  color: $brand-color;
}

.text-navy {
  color: $navy !important;
}

.no-left-padding {
  padding-left: 0px !important;
}

.text-amber {
  color: $amber !important;
}

.text-teal {
  color: $teal !important;
}

.text-burgundy {
  color: darken($burgundy, 20%) !important;
}

.border-light {
  border: 1px solid $gray-300;
}

.border-teal {
  color: #71a28a;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #e3e3e3;
}

.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}

.d-block {
  display: block !important;
}
//Cards
.ant-card {
  border: none;
  box-shadow: $shadow;
  margin-bottom: 30px;
  border-radius: 0.5rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
}

.card-columns .card {
  margin-bottom: 30px;
}

// directory
.company-card-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: center;

  .company-card {
    display: block;
    -ms-flex: 0 0 29.16666667%;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
    margin-left: 8px;
    margin-right: 8px;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
  }
}

.directory-card {
  .ant-card-body {
    padding: 0 !important;
  }
  overflow: hidden;
  .directory-bg {
    background-color: rgba($primary, 0.9);
  }
  .directory-overlay {
    padding: 5px;
    background-image: url("http://blonk.co/demo/SOBlonk/assets/images/bg-1.png");
    background-size: cover;
    background-position: center center;
  }
  img {
    position: relative;
    top: 60px;
  }
}

.directory-card-height-fixed {
  min-height: 420px;
}
.directory-job-card-height-fixed {
  min-height: 380px;
}

/* ==============
  Pagination
===================*/
.page-link {
  color: $primary;
}
.page-item.active .page-link {
  background-color: $amber;
  border-color: $amber;
}
.page-link:focus,
.page-link:hover {
  color: $dark;
  background-color: $gray-200;
  outline: none;
}

/* ==============
  Popover & Tooltips
===================*/

.popover-header {
  margin-top: 0;
}

.tooltip .tooltip-inner {
  padding: 4px 10px;
}

.smooth {
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}
.fs-account {
  color: #495057;
  font-size: 14px;
  font-weight: 400;
}
.fs-register {
  font-size: 14px;
  font-weight: 500;
}
