.mce-content-body {
  &.mce-edit-focus {
    img {
      /* Your styling for the img element goes here */
      max-height: 100px;
      width: auto; /* For example, make the image fill its container */
      margin: 15px;
      /* Add more styling as needed */
    }
  }
}
.mce-content-body {
  img {
    /* Your styling for the img element goes here */
    max-height: 100px;
    width: auto; /* For example, make the image fill its container */
    margin: 15px;
    /* Add more styling as needed */
  }
}
