// .job-listing-table {
//   .dataTable {
//     thead {
//       th {
//         &:nth-child(1) {
//           width: 10%;
//         }
//         &:nth-child(2) {
//           width: 20%;
//         }
//         &:nth-child(3) {
//           width: 20%;
//         }
//         &:nth-child(6) {
//           width: 10%;
//         }
//         &:nth-child(7) {
//           width: 12%;
//         }
//         &:nth-child(8) {
//           width: 15%;
//         }
//       }
//     }
//   }
// }
// .filter-search {
//   border-color: lightgray !important;
// }

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 35px !important;
  min-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #2e4a79;
  color: #2e4a79 !important;
  margin-right: 8px;
}
.ant-pagination-item-active a {
  color: #2e4a79 !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
  font-size: 14px !important;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-prev
  .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini
  .ant-pagination-next
  .ant-pagination-item-link {
  width: 10.5px !important;
  height: 18.01px !important;
  margin-top: 5px !important;
}

.ant-select-single.ant-select-show-arrow,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 16px !important;
}

.add-job-language-field {
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    margin-top: 2px !important;
  }
}
.add-job-language-field {
  .ant-select-selection-item {
    font-size: 14px !important;
  }
}

.add-job-currency-field {
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    margin-top: 2px !important;
  }
}
.add-job-currency-field {
  .ant-select-selection-search-input {
    margin-top: 2px !important;
  }
}
.add-job-currency-field {
  .ant-select-selection-item {
    font-size: 14px !important;
  }
}
.add-job-form-job-field {
  .ant-select-selection-placeholder {
    margin-left: 4px !important;
  }
}

.add-job-company-field {
  .ant-select-selection-search-input {
    margin-top: 2px !important;
  }
}
.add-job-company-field {
  .ant-select-selection-placeholder {
    margin-top: 7px !important;
    font-size: 14px !important;
  }
}

.add-job-lead-recruiter-field {
  .ant-select-selection-item {
    margin-top: 1px !important;
  }
}

.add-job-lead-recruiter-field .ant-select-selection-placeholder {
  margin-top: 6px !important;
}

.search-select .ant-select-selection-placeholder {
  margin-top: 12px !important;
}
.ant-spin-spinning {
  min-height: 100% !important;
}
