@import "../../assets/scss/variables";

.side-menu {
  padding: 0;
  width: 240px;
  z-index: 10;
  background: $primary;
  background: -moz-linear-gradient(top, $primary 0, $secondary 100%);
  background: -webkit-linear-gradient(top, $primary 0, $secondary 100%);
  background: linear-gradient(to bottom, $primary 0, $secondary 100%);
  -webkit-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  padding-top: 10px;
  position: fixed;
  top: 70px;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;

  .badge {
    margin-top: 4px;
  }

  .menu-title {
    padding: 12px 20px !important;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: lighten($leftbar-menu-color, 15%);
  }

  ul {
    padding: 0;

    li {
      list-style: none;

      &.active {
        .menu-arrow i {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      a {
        color: $white;
        display: block;
        padding: 13px 20px;
        font-size: 13.3px;
        position: relative;
        transition: all 0.5s;

        &.active {
          color: $white;
          background: lighten($primary, 10%);
        }

        i {
          display: inline-block;
          font-size: 16px;
          line-height: 17px;
          vertical-align: middle;
          width: 20px;
        }

        span {
          vertical-align: middle;
          margin-left: 7px;
        }
        &:hover,
        &:focus,
        &:active {
          color: $white;
          background: lighten($primary, 5%);
          text-decoration: none;
        }
      }
    }
  }
}

//full screen
.enlarged {
  .wrapper {
    .side-menu {
      position: fixed;
      width: 70px;
      z-index: 5;
      -webkit-transition: all 0.15s;
      transition: all 0.15s;

      ul {
        li {
          position: relative;
          white-space: nowrap;
          a {
            padding: 20px 20px;
            min-height: 56px;

            i {
              font-size: 24px;
              margin-right: 20px !important;
            }
          }

          &:hover > a {
            position: relative;
            width: 260px;
            color: $white;
            background: lighten($primary, 5%);
          }
          &:hover > ul {
            display: block;
            left: 70px;
            position: absolute;
            width: 190px;
            height: auto !important;

            a {
              box-shadow: none;
              padding: 8px 20px;
              position: relative;
              width: 190px;
              z-index: 6;

              &:hover {
                color: $white;
              }
            }
          }
          &:hover {
            a {
              span {
                display: inline;
              }

              &.open {
                :after {
                  display: none;
                }
              }

              &.active {
                :after {
                  display: none;
                }
              }
            }
          }
        }

        ul {
          margin-top: -2px;
          padding-bottom: 5px;
          padding-top: 5px;
          z-index: 9999;
          background-color: $primary;

          li {
            &.active {
              a {
                color: $white;
              }
            }
            a {
              span {
                display: none;
                padding-left: 10px;
                &.float-right {
                  -ms-transform: rotate(270deg);
                  -webkit-transform: rotate(270deg);
                  position: absolute;
                  right: 20px;
                  top: 12px;
                  transform: rotate(270deg);
                }
              }
            }

            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
        }
      }

      .menu-title,
      .menu-arrow,
      .badge,
      .collapse.in {
        display: none !important;
      }
      .nav.collapse {
        height: inherit !important;
      }
    }
  }
}
//sub menu styling
.submenu li a {
  padding: 8px 20px 8px 10px;
  color: $white;
  display: block;
  transition: all 0.5s;
  &:focus {
    background-color: darken($primary, 10%);
  }

  &:hover {
    background-color: $primary;
    color: rgba($white, 0.8);
  }
}

.submenu > li > a {
  padding-left: 50px;
}

.submenu li.active {
  > a {
    color: $white;
    background-color: $primary;
  }
}
