.preview-modal {
  width: auto !important;
  .ant-modal-content {
    width: 95%;
  }
}

.company-sector-field .ant-select-selection-placeholder {
  margin-top: 7px !important;
}
.company-size-field .ant-select-selection-placeholder {
  margin-top: 7px !important;
}
