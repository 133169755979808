.ant-image .ant-image-mask {
  border-radius: 50% !important;
  scale: 0.9 !important;
}

.hover-video .video-view-icon,
.hover-video .video-delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px; /* Adjust icon size as needed */
  height: 25px; /* Adjust icon size as needed */
  background-size: cover;
  background-color: #00000099; /* Adjust the background color and opacity */
}

.hover-video .video-view-icon {
  transform: translate(-100%, -50%); /* Adjust positioning of the view-icon */
  background-image: url("./icons/video.svg");
  /* Replace with your icon's path */
  background-color: #00000099; /* Adjust the background color and opacity */
}

.hover-video .video-delete-icon {
  transform: translate(0, -50%); /* Adjust positioning of the delete-icon */
  background-image: url("./icons/delete.svg");
  background-color: #00000099;
  margin-left: 5px;
}

.hover-video .video-view-icon,
.hover-video .video-delete-icon {
  display: block;
  cursor: pointer;
  background-color: transparent;
}

.hover-video {
  position: relative;
  display: inline-block;
}
.hover-video .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hover-video .image-wrapper {
  border-radius: 100%; /* Apply border radius to create rounded shape */
  overflow: hidden; /* Ensure the image stays within the rounded shape */
}

.hover-video img {
  max-width: 100%;
}

.hover-video {
  opacity: 0.7; /* Adjust the opacity value for the container */
}

.hover-img .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.user-profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 160px !important;
  height: 160px !important;
}

.hover-img .image-wrapper img,
.hover-img .view-icon,
.hover-img .delete-icon {
  opacity: 0.7;
}

.hover-img .image-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.hover-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hover-img img {
  opacity: 0.8;
}
.hover-img .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hover-img .view-icon,
.hover-img .delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 26px;
  height: 24px;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-img .view-icon {
  transform: translate(-100%, -50%);
  background-image: url("./icons/eye.svg");
  background-color: rgba(0, 0, 0, 0.7);
}

.hover-img .delete-icon {
  transform: translate(0, -50%);
  background-image: url("./icons/delete.svg");
  background-color: rgba(0, 0, 0, 0.7);
  margin-left: 8px;
}

.hover-img .view-icon,
.hover-img .delete-icon {
  display: block;
  cursor: pointer;
  background-color: transparent;
  opacity: 1;
}
.z-2 {
  z-index: 2;
}

.myprofile-location .ant-select-selection-placeholder {
  margin-top: 6px !important;
  margin-left: -6px !important;
  font-size: 14px !important;
}

.myprofile-location .ant-select-selection-item {
  font-size: 14px !important;
}
.myprofile-location-input .ant-select-selection-search-input {
  height: 32px !important;
  margin-left: 4px !important;
}

.myprofile-location-input .ant-select-selection-placeholder {
  margin-left: 2px !important;
}

.experience-container {
  display: flex;
}

@media (max-width: 1000px) {
  .experience-container {
    display: flex;
    flex-direction: column;
  }
}

//picture
.hover-img-logo .image-wrapper img,
.hover-img-logo .view-icon,
.hover-img-logo .delete-icon {
  opacity: 0.6;
}

.hover-img-logo .image-wrapper {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.hover-img-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hover-img-logo img {
  opacity: 0.7;
}
.hover-img-logo .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 50%;
}

.hover-img-logo .view-icon,
.hover-img-logo .delete-icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hover-img-logo .view-icon {
  transform: translate(-100%, -50%);
  background-image: url("./icons/eye.svg");
  background-color: rgba(0, 0, 0, 0.7);
}

.hover-img-logo .delete-icon {
  transform: translate(0, -50%);
  background-image: url("./icons/delete.svg");
  background-color: rgba(0, 0, 0, 0.7);
  margin-left: 5px;
}

.hover-img-logo .view-icon,
.hover-img-logo .delete-icon {
  display: block;
  cursor: pointer;
  background-color: transparent;
  opacity: 1;
}
