.font-size-custom h5.ant-typography.text-primary {
  font-size: 20px;
  font-weight: 600 !important;
}
.custom-card {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #495057;
}
.ant-table-tbody {
  color: #495057;
}
.custom-card a {
  color: #495057;
}
.custom-tabs .ant-tabs-tab {
  justify-content: flex-end;
}
.custom-card .ant-tabs-card.ant-tabs-top .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0px 35px 35px 0;
  background-color: white;
  box-shadow: 11px 0px 17px rgba(0, 0, 0, 0.25);
  border-color: none;
  /* padding: 18px 30px 18px 15px !important; */
  padding: 18px 20px 18px 10px !important;
  position: relative;
  border: none;
  flex: 1;
  width: 100%;
}
.custom-card .ant-tabs-nav-list {
  width: 100% !important;
}
.custom-card .ant-card-body {
  padding: 0px !important;
}
.custom-card .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.custom-card .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #2e4a79 !important;
  border: none !important;
}
/* .custom-card .ant-tabs-tab.ant-tabs-tab-active .ant-typography {
  font-weight: 600 !important;
} */

.custom-card .ant-tabs-tab.ant-tabs-tab-active .ant-typography {
  color: white !important;
}
.custom-card div#rc-tabs-0-tab-7 {
  padding-left: 10px;
}
.custom-card .designing1 {
  font-size: 18px;
  font-weight: 400;
  /* margin-left: -11px; */
  color: #000000 !important;
}
.custom-card .designing2 {
  font-size: 18px;
  font-weight: 400;
  color: #000000 !important;
}
.custom-card .designing3 {
  font-size: 18px;
  font-weight: 400;
  /* margin-left: 17px; */
  color: #000000 !important;
}
button#rc-tabs-2-more {
  display: none;
}
button#rc-tabs-4-more {
  display: none;
}
.custom-card .designing7 {
  font-size: 18px;
  font-weight: 400;
  color: #000000 !important;
}
.d-7 {
  margin-left: -100px !important;
}
/* .myLikesTab {
  margin-left: -130px !important;
} */
/* .custom-card article.ant-typography.designing2 {
  margin-left: 8px;
} */

.custom-card .ant-tabs-tab:nth-child(2) {
  z-index: -2;
}
.custom-card .ant-tabs-tab:nth-child(3) {
  z-index: -3;
}
.custom-card .ant-tabs-tab:nth-child(4) {
  z-index: -4;
}
.custom-card .ant-tabs-tab:nth-child(5) {
  z-index: -5;
}
.custom-card .ant-tabs-tab:nth-child(6) {
  z-index: -6;
}
.custom-card .ant-tabs-tab:nth-child(7) {
  z-index: -7;
  border-radius: 0px 35px 0px 0px !important;
}
.custom-card button#rc-tabs-0-more {
  display: none;
}
.custom-card
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: -30px;
  /* margin-top: 5px; */
}

.custom-card .ant-tabs-nav-wrap {
  display: none;
}
.custom-card .ant-col.ant-col-24 {
  margin-top: 20px;
}

.custom-tabs .ant-tabs-nav-list {
  width: 100%;
}
/* .custom-card div#rc-tabs-0-tab-7 {
  padding-left: 20px;
} */
.custom-card.ant-tabs-tab:nth-child(7) {
  padding-right: 0 !important;
  padding-left: 50px !important;
}
.custom-ant-tabs-tab {
  position: relative;
}
.ant-badge.ant-badge-not-a-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.blip {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: #bc0000;
  border: 1px solid white;
  border-radius: 50%;
  top: 0;
  right: -5px;
}
.blip-parent {
  position: relative;
}
